<template>
	<div
		class="eventWraper module_part"
		v-if="selarticleList && selarticleList.length > 0"
	>
		<div class="rsTitle rsTitleBg flex align_items_c justify_content_sb">
			<div class="rsTitleLeft flex align_items_c justify_content_sb">
				<div class="maincolor">{{ partName }}</div>
				<div
					class="flex align_items_c pointer rsTitleLeft_more"
					v-if="type != 4"
					@click="toPartPage"
				>
					<div class="rsTitleLeft_point"></div>
					<div class="rsTitleLeft_point"></div>
					<div class="rsTitleLeft_point"></div>
				</div>
			</div>
		</div>
		<!-- 精选专栏显示这部分 -->
		<div v-if="type == 2" class="top-part flex">
			<div class="img-box">
				<img src="" />
			</div>
			<p class="img_title">这个数据是新的</p>
			<p class="img_content"
				>这里也是新数据的内容，好像是专栏作者信息简介之类的内容</p
			>
		</div>
		<div class="img_text" v-if="type == 3 || type == 4 || type == 5">
			<div
				class="img_text_box flex align_items_c"
				v-for="(item, index) in selarticleList"
				:key="index"
			>
				<div
					v-if="!unShowImg"
					class="activity-img-box wzitem_sub_Right_show"
					:class="{
						wzitem_sub_Right_show: item.pic != null && item.pic.length > 0,
					}"
				>
					<router-link
						target="_blank"
						:to="{
							name: type == 4 ? '/gw_detail' : 'ac_detail',
							params: { id: item.id },
						}"
					>
						<img
							class="wzitem_sub_img pointer"
							:src="item.pic"
							:key="item.pic"
							alt=""
						/>
					</router-link>
				</div>
				<router-link
					target="_blank"
					:to="{
						name: type == 4 ? '/gw_detail' : '/ac_detail',
						params: { id: item.id },
					}"
				>
					<div
						class="wzitem_sub_Left"
						:class="{
							reItem_Left_full: item.pic != null && item.pic.length > 0,
						}"
					>
						<div class="wzitem_sub_Left_content hovercolor pointer">
							{{
								localLanguage == 2
									? item.title
										? item.title
										: item.title
									: item.title
							}}
						</div>
						<div class="wzitem_sub_Left_time">{{
							item.start_time || item.release_time
						}}</div>
					</div>
				</router-link>
			</div>
		</div>
		<div
			v-else
			class="MainRight_2 MainRight_3 steps homesteps eventWraper_content"
		>
			<div
				class="description"
				slot="description"
				v-for="(item, index) in selarticleList"
				:key="index"
			>
				<div v-if="type != 2" class="flex align_items_c">
					<span class="stepIcon"></span>
					<div class="stepTime">{{ item.release_time }}</div>
				</div>
				<router-link
					target="_blank"
					:to="{
						name:
							type == 1
								? '/kuaixun_wz'
								: type == 4 || type == 5
								? '/ac_detail'
								: '/gw_detail',
						params: { id: item.id },
					}"
				>
					<div class="stepsText ellipsis_multiple pointer hovercolor">
						{{
							localLanguage == 2
								? item.title
									? item.title
									: item.title
								: item.title
						}}
					</div>
				</router-link>
				<div
					v-if="type == 2"
					class="flex align_items_c"
					:class="{ 'time-bottom': type == 2 }"
				>
					<div class="stepTime">{{ item.release_time }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import clipboard from "clipboard";
import { newBus } from "@/components/pc/topHeader2.vue";

import {
	getActivityList,
	homeHotList,
	hotSearch,
	getArticleList,
} from "@/api/home";
export default {
	//  type: 1精选快讯
	props: ["type", "unShowImg"],
	data() {
		return {
			localLanguage: 1,
			partName: "",
			selarticleList: [],
		};
	},
	mounted() {
		this.titleName();
		newBus.$on("global-notification", (data) => {
			let localLanguage = sessionStorage.getItem("localLanguage");
			if (localLanguage == "繁体中文") {
				this.localLanguage = 1;
			} else if (localLanguage == "english") {
				this.localLanguage = 2;
			} else {
				this.localLanguage = 0;
			}
		});
		let localLanguage = sessionStorage.getItem("localLanguage");
		if (localLanguage == "繁体中文") {
			this.localLanguage = 1;
		} else if (localLanguage == "english") {
			this.localLanguage = 2;
		} else {
			this.localLanguage = 0;
		}
	},
	methods: {
		titleName() {
			switch (+this.type) {
				case 1:
					this.partName = "热门快讯";
					// 获取快讯列表
					// this.getFlashList();
					this.getArticleList({ category_id: 0, page: 1, size: 10, type: 2 });
					break;
				case 2:
					this.partName = "精选专栏";
					break;
				case 3:
					this.partName = "活动专区";
					break;
				case 4:
					this.partName = "热门文章";
					// 获取热搜列表
					this.getHotSearchList();
					break;
				case 5:
					this.partName = "活动推荐";
					// 获取推荐活动列表
					this.getActivityList();
					break;
				default:
					this.partName = "快讯";
			}
		},
		toPartPage() {
			switch (+this.type) {
				case 1:
					this.$router.push({ path: "/news" });
					break;
				case 2:
					this.$router.push({ path: "/columns" });
					break;
				case 3:
					this.$router.push({ path: "/events" });
					break;
				case 4:
					this.$router.push({ path: "/events" });
					break;
				case 5:
					this.$router.push({ path: "/events" });
					break;
				default:
					this.$router.push({ path: "/news" });
			}
		},
		// 获取精选快讯
		getFlashList() {
			let res = JSON.parse(sessionStorage.getItem("homeflashList"));
			if (res != null && res.code == 200) {
				this.selarticleList = res.data.hotLists;
			}
			homeHotList({ type: 2 })
				.then((res) => {
					sessionStorage.setItem("homeflashList", JSON.stringify(res));
					if (res.code == 200) {
						this.selarticleList = res.data.hotLists;
					} else {
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},

		getArticleList(obj) {
			let res = JSON.parse(sessionStorage.getItem("homeflashList"));
			if (res != null && res.code == 200) {
				this.selarticleList = res.data.hotLists;
			}
			getArticleList(obj)
				.then((res) => {
					sessionStorage.setItem("homeflashList", JSON.stringify(res));
					if (res.code == 200) {
						this.selarticleList = res.data.list;
					} else {
					}
				})
				.catch((error) => {});
		}, // 获取热搜数据
		getHotSearchList() {
			let res = JSON.parse(sessionStorage.getItem("homehotSearchList"));
			if (res != null && res.code == 200) {
				this.selarticleList = res.data;
			}
			hotSearch({ type: 2 })
				.then((res) => {
					sessionStorage.setItem("homehotSearchList", JSON.stringify(res));
					if (res.code == 200) {
						this.selarticleList = res.data;
					} else {
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
		// 获取推荐活动
		getActivityList() {
			let res = JSON.parse(sessionStorage.getItem("homegetActivityListData"));
			if (res != null && res.code == 200) {
				this.selarticleList = res.data.list;
			}
			getActivityList({ type: 0, page: 1, size: 10, is_top: 1 })
				.then((res) => {
					sessionStorage.setItem(
						"homegetActivityListData",
						JSON.stringify(res)
					);
					if (res.code == 200) {
						this.selarticleList = res.data.list;
					} else {
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
	},
};
</script>

<style scoped>
.top-part {
	border-radius: 10px;
	background: rgba(225, 227, 241, 0.1);
	padding: calc(100vw * 17 / 1440);
	margin-top: calc(100vw * 25 / 1440);
	flex-direction: column;
	align-items: center;
}
.top-part .img-box {
	border: 1px solid red;
	width: calc(100vw * 60 / 1440);
	height: calc(100vw * 60 / 1440);
	border-radius: 50%;
}
.top-part img {
	width: 100%;
	height: 100%;
	border-radius: 50%;
	object-fit: cover;
}
.top-part .img_title {
	margin-top: calc(100vw * 12 / 1440);
	color: #fff;
	font-size: clamp(10px, calc(100vw * 14 / 1440), 14px);
	line-height: 1;
	font-weight: 600;
}
.top-part .img_content {
	margin-top: calc(100vw * 14 / 1440);
	color: #767676;
	font-size: clamp(8px, calc(100vw * 12 / 1440), 12px);
	line-height: 1.2;
}
.description {
	padding-bottom: clamp(12px, calc(100vw * 1 / 90), 16px);
	margin-top: clamp(12px, calc(100vw * 1 / 72), 20px);
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.stepIcon {
	width: 5px;
	height: 5px;
	margin-right: 10px;
	border-radius: 50%;
	background: linear-gradient(
		21deg,
		#9945ff -24%,
		#8752f3 -3%,
		#5497d5 15%,
		#43b4ca 25%,
		#28e0b9 36%,
		#19fb9b 60%
	);
}
.stepTime {
	font-size: 12px;
	line-height: 12px;
	color: #b2b2b2;
}
.time-bottom {
	margin-top: 15px;
}
.stepsText {
	margin-top: 5px;
	font-family: PingFang SC;
	font-size: clamp(12px, calc(100vw * 14 / 1440), 14px);
	line-height: 1.5;
	font-weight: 500;
	letter-spacing: 0em;
	/* 纯白 */
	color: #ffffff;
}
.btn {
	width: 40px;
	height: 25px;
	margin-left: 12px;
	line-height: 25px;
	text-align: center;
	background: #fff;
	border-radius: 13px;
	font-size: 12px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #a0a0a9;
}
.activeBtn {
	color: #0056ff;
}

.homesteps_el {
	margin-top: 30px;
}
.homesteps .el-step__main {
	height: auto;
	margin-left: 10px;
}
.homesteps .stepsVal {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
	margin-top: 5px;

	line-height: 21px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;

	font-size: 14px;
	font-weight: normal;

	/* 黑色主色（字段一级） */
	color: #333333;
}

.btnitem {
	margin-top: 7px;
}

.btnitem img {
	width: 14px;
	margin-right: 3px;
}
.fx div {
	/* font-size: 12px;
	font-family:  "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	color: #a0a0a8; */

	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 13px;
	font-weight: normal;
	line-height: 13px;
	letter-spacing: 0em;

	color: #b2b2b2;
}
.MainRight_3 {
	padding-top: 0px;
	/* height: 600px; */
}
</style>

<style>
.eventWraper .el-step.is-vertical .el-step__line {
	top: 0px !important;
	/* margin-top: 10px; */
}
.eventWraper .el-step__description {
	margin-top: 0;
	padding-right: 0% !important;
}

.eventWraper .el-step__icon {
	top: -13px;
}

.eventWraper .el-step__main {
	padding-top: 0px;
	margin-top: -8px;
}

.homesteps.eventWraper_content {
	background: transparent !important;
}

.img_text_box {
	margin-top: 30px;
}

.module_part .wzitem_sub_img {
	width: clamp(120px, calc(100vw * 1 / 9), 160px);
	/* height: calc(100vw * 9 / 144); */
	aspect-ratio: 16/9;
	border-radius: 6px;
	object-fit: cover;
	margin-right: clamp(10px, calc(100vw * 3 / 144), 30px);
}

.module_part .wzitem_sub_Left_content {
	font-family: AlibabaPuHuiTi;
	font-size: clamp(10px, 1vw, 14px);
	line-height: 1.4;
	font-weight: bold;
	text-transform: uppercase;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
	overflow: hidden;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 3;
}

.wzitem_sub_Left_time {
	font-family: PingFang SC;
	font-size: 12px;
	font-weight: normal;
	line-height: 16px;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
	margin-top: 20px;
}
</style>
