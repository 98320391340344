var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.selarticleList && _vm.selarticleList.length > 0)?_c('div',{staticClass:"eventWraper module_part"},[_c('div',{staticClass:"rsTitle rsTitleBg flex align_items_c justify_content_sb"},[_c('div',{staticClass:"rsTitleLeft flex align_items_c justify_content_sb"},[_c('div',{staticClass:"maincolor"},[_vm._v(_vm._s(_vm.partName))]),(_vm.type != 4)?_c('div',{staticClass:"flex align_items_c pointer rsTitleLeft_more",on:{"click":_vm.toPartPage}},[_c('div',{staticClass:"rsTitleLeft_point"}),_c('div',{staticClass:"rsTitleLeft_point"}),_c('div',{staticClass:"rsTitleLeft_point"})]):_vm._e()])]),(_vm.type == 2)?_c('div',{staticClass:"top-part flex"},[_vm._m(0),_c('p',{staticClass:"img_title"},[_vm._v("这个数据是新的")]),_c('p',{staticClass:"img_content"},[_vm._v("这里也是新数据的内容，好像是专栏作者信息简介之类的内容")])]):_vm._e(),(_vm.type == 3 || _vm.type == 4 || _vm.type == 5)?_c('div',{staticClass:"img_text"},_vm._l((_vm.selarticleList),function(item,index){return _c('div',{key:index,staticClass:"img_text_box flex align_items_c"},[(!_vm.unShowImg)?_c('div',{staticClass:"activity-img-box wzitem_sub_Right_show",class:{
					wzitem_sub_Right_show: item.pic != null && item.pic.length > 0,
				}},[_c('router-link',{attrs:{"target":"_blank","to":{
						name: _vm.type == 4 ? '/gw_detail' : 'ac_detail',
						params: { id: item.id },
					}}},[_c('img',{key:item.pic,staticClass:"wzitem_sub_img pointer",attrs:{"src":item.pic,"alt":""}})])],1):_vm._e(),_c('router-link',{attrs:{"target":"_blank","to":{
					name: _vm.type == 4 ? '/gw_detail' : '/ac_detail',
					params: { id: item.id },
				}}},[_c('div',{staticClass:"wzitem_sub_Left",class:{
						reItem_Left_full: item.pic != null && item.pic.length > 0,
					}},[_c('div',{staticClass:"wzitem_sub_Left_content hovercolor pointer"},[_vm._v(" "+_vm._s(_vm.localLanguage == 2 ? item.title ? item.title : item.title : item.title)+" ")]),_c('div',{staticClass:"wzitem_sub_Left_time"},[_vm._v(_vm._s(item.start_time || item.release_time))])])])],1)}),0):_c('div',{staticClass:"MainRight_2 MainRight_3 steps homesteps eventWraper_content"},_vm._l((_vm.selarticleList),function(item,index){return _c('div',{key:index,staticClass:"description",attrs:{"slot":"description"},slot:"description"},[(_vm.type != 2)?_c('div',{staticClass:"flex align_items_c"},[_c('span',{staticClass:"stepIcon"}),_c('div',{staticClass:"stepTime"},[_vm._v(_vm._s(item.release_time))])]):_vm._e(),_c('router-link',{attrs:{"target":"_blank","to":{
					name:
						_vm.type == 1
							? '/kuaixun_wz'
							: _vm.type == 4 || _vm.type == 5
							? '/ac_detail'
							: '/gw_detail',
					params: { id: item.id },
				}}},[_c('div',{staticClass:"stepsText ellipsis_multiple pointer hovercolor"},[_vm._v(" "+_vm._s(_vm.localLanguage == 2 ? item.title ? item.title : item.title : item.title)+" ")])]),(_vm.type == 2)?_c('div',{staticClass:"flex align_items_c",class:{ 'time-bottom': _vm.type == 2 }},[_c('div',{staticClass:"stepTime"},[_vm._v(_vm._s(item.release_time))])]):_vm._e()],1)}),0)]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box"},[_c('img',{attrs:{"src":""}})])
}]

export { render, staticRenderFns }